const base = require('base/product/base');

function getEventPersonalizationsModal() {
    var lengthFormName = $('#personalization-form-name').val() ? $('#personalization-form-name').val().length : 0;
    var lengthFormNumber = $('#personalization-form-number').val() ? $('#personalization-form-number').val().length : 0;
    if ((lengthFormName + lengthFormNumber) === 0) {
        $('.summaryContainer-PDP').addClass('d-none');
    }
}
function creatingArrayForNumberOptions() {
    arrayOptionNumberPersonalization = $.map(
        $('#numberPersonalization select.options-select option'),
        function (option) {
            return {
                // We get the id that is stored in the ISML attribute data-value-id
                optionId: option.dataset.valueId,

                // The value attribute contains the URL to request for a option change
                value: option.value,

                // We also get useful prices values, added by customization on data attributes
                price: option.dataset.price,
                priceValue: Number(option.dataset.priceValue)
            };
        }
    );
}
function creatingArrayForNameOptions() {
    arrayOptionNamePersonalization = $.map(
        $('#namePersonalization select.options-select option'),
        function (option) {
            return {
                // We get the id that is stored in the ISML attribute data-value-id
                optionId: option.dataset.valueId,

                // The value attribute contains the URL to request for a option change
                value: option.value,

                // We also get useful prices values, added by customization on data attributes
                price: option.dataset.price,
                priceValue: Number(option.dataset.priceValue)
            };
        }
    );
}
function simulateSelectElementChangeEvent(selector, newValue) {
    $(selector) // Query the div that wraps the <select> element
        .find('select.options-select') // Gets the select element
        .val(newValue); // Updates the option selected with the corresponding value
    // Fires the event 'change' in order to call the corresponding handler.
}
function personalizationNameConstructor() {
    getEventPersonalizationsModal();
    creatingArrayForNameOptions();
    var lengthFormName = $('#personalization-form-name').val() ? $('#personalization-form-name').val().length : 0;
    var nameValue = arrayOptionNamePersonalization[lengthFormName] ? arrayOptionNamePersonalization[lengthFormName].value : 0;
    simulateSelectElementChangeEvent(
        '[data-option-id="namePersonalization"]',
        nameValue
    );
}
function personalizationNumberConstructor() {
    getEventPersonalizationsModal();
    creatingArrayForNumberOptions();
    var lengthFormNumber = $('#personalization-form-number').val() ? $('#personalization-form-number').val().length : 0;
    var numberValue = arrayOptionNumberPersonalization[lengthFormNumber] ? arrayOptionNumberPersonalization[lengthFormNumber].value : 0;
    simulateSelectElementChangeEvent(
        '[data-option-id="numberPersonalization"]',
        numberValue
    );
}

function setPersonalizations(personalizationData) {
    $('#personalization-form-name').val(personalizationData.personalization.nameModal);
    $('#personalization-form-number').val(personalizationData.personalization.numberModal);
    $('.nameSummary').text(personalizationData.personalization.nameModal);
    $('.namePriceSummary').text(personalizationData.personalization.costNameModal);
    $('.numberSummary').text(personalizationData.personalization.numberModal);
    $('.numberPriceSummary').text(personalizationData.personalization.costNumberModal);
    $('.finalPriceSummary').text(personalizationData.personalization.totalCostModal);
    $('.nameSummary-PDP').text(personalizationData.personalizationPDP.nameModal);
    $('.namePriceSummary-PDP').text(personalizationData.personalizationPDP.costNameModal);
    $('.numberSummary-PDP').text(personalizationData.personalizationPDP.numberModal);
    $('.numberPriceSummary-PDP').text(personalizationData.personalizationPDP.costNumberModal);
    $('.finalPriceSummary-PDP').text(personalizationData.personalizationPDP.totalCostModal);
    $('.summaryContainer-PDP').removeClass('d-none');
    $('.addNewPersonalization').text('Editar');
    personalizationNameConstructor();
    personalizationNumberConstructor();
}
/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 * Update/user             Description                               Date   
 * Cristian Cortes     historia 13800. Se agrega una modificación    05/04/2024
 *                     en larespuesta del no para que este busque  
 *                     el botón de cantidad más cercano
 */
function getQuantitySelector($el) {
    if (($el).hasClass('checkout-btn-wishlist-add')) {
        return $('#quantity-for-wishlist-card');
    }
    return $el && $('.set-items').length ? $($el).closest('.product-detail').find('.quantity-select') : $($el).closest('.product-detail').find('.quantity-selector-wrapper .quantity-select');
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if (!($productContainer.parent('.bonus-product-item').length > 0)) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) { return ''; }
    var html = '';
    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) { html += '<div class="attribute-values">' + attribute.label + ': ' + attribute.value + '</div>'; });
        }
    });

    return html;
}

function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

function initializeZoom() {
    try {
        $('.zoom').zoom();
    } catch (e) {
        // ignore
    }
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, $productContainer) {
    $('.zoom').trigger('zoom.destroy');
    var carousel = $productContainer.find('.carousel.product__images-carousel');
    $(carousel).carousel('dispose');
    var carouselId = $(carousel).attr('id');
    $(carousel).empty().append('<ol class="carousel-indicators custom-top"></ol><div class="carousel-inner" role="listbox"></div><a class="carousel-control-prev" href="#' + carouselId + '" role="button" data-slide="prev"><span class="fa icon-prev" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('prev') + '</span></a><a class="carousel-control-next" href="#' + carouselId + '" role="button" data-slide="next"><span class="fa icon-next" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('next') + '</span></a>');
    for (var i = 0; i < imgs.large.length; i++) {
        $('<div class="carousel-item"><div class="product-image-container zoom img-zoom"><img src="' + imgs.large[i].url + '" class="d-block img-fluid" alt="' + imgs.large[i].alt + ' image number ' + parseInt(imgs.large[i].index, 10) + '" title="' + imgs.large[i].title + '" itemprop="image" /></div></div>').appendTo($(carousel).find('.carousel-inner'));
        $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));
    }

    if (imgs['360'].length) {
        $('<div class="carousel-item"><div class="product-image-container "><div id="mySpriteSpin"></div></div></div>').appendTo($(carousel).find('.carousel-inner'));
        $('<li data-target="#' + carouselId + '" data-slide-to="' + imgs.large.length + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));
    }
    $($(carousel).find('.carousel-item')).first().addClass('active');
    $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
    if (imgs.large.length === 1 && imgs['360'].length == 0) {
        $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
    }
    $(carousel).carousel();
    $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);

    initializeZoom();

    if (imgs['360'].length) {
        if ($('input[name=imagesCarouselQuickView]').length === 0) {
            $('#mySpriteSpin').spritespin({
                source: imgs['360'].map(image => image.url),
                width: 650,
                height: 520,
                sense: -1,
                responsive: true,
                animate: false,
                plugins: [
                    '360',
                    'drag'
                ]
            });
        }
    }
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        // baseExt.updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);

        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    createCarousel(response.product.images, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length ? $('.prices .price', $productContainer) : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    var pageUUID = $('#pageUUID').val();
    if (localStorage.getItem(pageUUID + '_' + response.product.id + '_personalizationsData')) {
        var personalizationsDataGet = JSON.parse(Buffer.from(localStorage.getItem(pageUUID + '_' + response.product.id + '_personalizationsData'), 'base64').toString(
            'utf-8'
        ));
        setPersonalizations(personalizationsDataGet);
    }

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }
    // Update attributes
    $productContainer.find('.main-attributes').empty().html(getAttributesHtml(response.product.attributes));
}

function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect', { url: selectedValueUrl, container: $productContainer });
        var pageUUID = $('#pageUUID').val();

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                $('#productSKU').text(data.product.id);
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect', { data: data, container: $productContainer });
                if (localStorage.getItem(pageUUID + '_' + data.product.id + '_personalizationsData')) {
                    var personalizationsDataGet = JSON.parse(Buffer.from(localStorage.getItem(pageUUID + '_' + data.product.id + '_personalizationsData'), 'base64').toString(
                        'utf-8'
                    ));
                    setPersonalizations(personalizationsDataGet);
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

function colorAttribute() {
    $(document).on('click', '[data-attr="color"] button', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if ($(this).attr('disabled')) {
            return;
        }
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }

        attributeSelect($(this).attr('data-url'), $productContainer);
    });
}
/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;
    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else if ($('.wishlist-productID').length) {
        pid = $($el).closest('.btn-checkout-container').find('.wishlist-productID').val();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }
    return pid;
}

// hotfix/DAB-3712 //
function validateProductLimited() {
    let faceUrl = $('#faceUrl').val();
    $('.add-to-cart').prop('disabled', true);
    $('.variationAttribute').addClass('d-none');
    htmlString = '<div class="row">'
        + '<div class="col-12">'
        + '<div class="card-stamps-limitedProduct">'
        + '<span class="stamp-user-limited-product">'
        + '<img src = "' + faceUrl + '" alt="face SVG" class="icon-limitedProduct face"/>'
        + ' Este producto no se encuentra disponible, ya que alcanzaste la cantidad de compra permitida por usuario.'
        + '</span>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('.load-islimited').remove();
    $('.load-islimited-added').empty().html(htmlString);
    $('.load-islimited-added').removeClass('d-none');
}
// hotfix/DAB-3712 //

function validateBigVolumeProduct(isBigVolume, basketHasBigVolumeInside, basketHasNormalVolumeInside) {
    if (
        (isBigVolume && basketHasBigVolumeInside) ||
        (isBigVolume && basketHasNormalVolumeInside) ||
        (!isBigVolume && basketHasBigVolumeInside)
    ) {
        $('.variationAttribute').addClass('d-none');
        $('.add-to-cart').addClass('d-none');
        $('.add-to-cart').prop('disabled', true);
        $('.variationAttribute,#btn-add-to-cart').css({ display: 'none' });
        $('#btn-cart-modal').removeClass('d-none');
        $('#btn-cart-modal').attr('disabled', false);
        $('.low-stock').css({ display: 'none' });
        $('.msg-container').removeClass('d-none');

        $('#btn-cart-modal').on('click', function () {
            $('#bigVolumeModal').modal('show');
            $('#btn-cart-modal').attr('disabled', true);
        });
        $('.close-message').on('click', function () {
            $('#bigVolumeModal').modal('hide');
        });
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}
/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}
/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}
/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}
/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content modal-content-custom-bonus">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '     <img class="close-img"/>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body modal-body-custom-bonus"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });

    $('#chooseBonusProductModal').on('shown.bs.modal', function () {
        var arrowSlider = $('span[data-arrow-slider]').attr('data-arrow-slider');
        var arrowSliderR = $('span[data-arrow-slider-r]').attr('data-arrow-slider-r');
        $('.modal-body-custom-bonus').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: "<img class='a-left control-c prev slick-prev slick-prev-bonus' src='" + arrowSlider + "'>",
            nextArrow: "<img class='a-right control-c next slick-next slick-next-bonus' src='" + arrowSliderR + "'>"
        });
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}
var multipleProductContainer = $('.body-multiple-products').children('.product-quickview.product-detail');
var locationCartPathName = $('#locationPathName').val();
function addToCart() {
    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.product-detail').each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val(),
                        options: getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }
        if (multipleProductContainer.length > 1) {
            setPids = [];
            multipleProductContainer.each(function () {
                if ($(this).hasClass('selected')) {
                    setPids.push({
                        // pid: $(this).attr('data-pid'),
                        pid: $(this).data('pid'),
                        qty: 1,
                        options: getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }
        pid = getPidValue($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
        }

        addToCartUrl = getAddToCartUrl();

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: getChildProducts(),
            quantity: getQuantitySelected($(this))
        };

        if (!$('.bundle-item').length) {
            form.options = getOptions($productContainer);
        }

        var storeId = $('.store-name').attr('data-store-id');
        form.checkoutRedirect = !!($(this).hasClass('checkout-btn-add') || storeId);

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    handlePostCartAdd(data);
                    var isBigVolume = $('#isBigVolume').val() ? JSON.parse($('#isBigVolume').val()) : false;
                    if (data.lastProductAddedIsLimited) { validateProductLimited(data); }
                    if (isBigVolume) { validateBigVolumeProduct(isBigVolume, data.basketHasBigVolumeInside, data.basketHasNormalVolumeInside); }
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                    miniCartReportingUrl(data.reportingURL);
                    if (location.pathname === locationCartPathName) {
                        location.reload();
                    }
                    if (data.redirectCheckout) {
                        window.location.href = data.redirectCheckout;
                    } else {
                        $('.minicart-link').trigger('click');
                    }
                    if (isBigVolume) {
                        $('#btn-cart-modal-bigVolume').removeClass('d-none');
                        $('#btn-cart-modal-bigVolume').attr('disabled', true);
                        $('.checkout-btn-add').attr('disabled', true);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

function addBonusProductsToCart() {
    $(document).on('click', '.add-bonus-products', function () {
        var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
        var queryString = '?pids=';
        var url = $('.choose-bonus-product-dialog').data('addtocarturl');
        var pidsObject = {
            bonusProducts: []
        };

        $.each($readyToOrderBonusProducts, function () {
            var qtyOption =
                parseInt($(this)
                    .data('qty'), 10);

            var option = null;
            if (qtyOption > 0) {
                if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                    option = {};
                    option.optionId = $(this).data('optionid');
                    option.productId = $(this).data('pid');
                    option.selectedValueId = $(this).data('option-selected-value');
                }
                pidsObject.bonusProducts.push({
                    pid: $(this).data('pid'),
                    qty: qtyOption,
                    options: [option]
                });
                pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
            }
        });
        queryString += JSON.stringify(pidsObject);
        queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
        queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
        $.spinner().start();
        $.ajax({
            url: url + queryString,
            method: 'POST',
            success: function (data) {
                $.spinner().stop();
                if (data.error) {
                    $('#chooseBonusProductModal').modal('hide');
                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-danger add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.errorMessage + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                    }, 3000);
                } else {
                    $('.configure-bonus-product-attributes').html(data);
                    $('.bonus-products-step2').removeClass('hidden-xl-down');
                    $('#chooseBonusProductModal').modal('hide');

                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.minicart-quantity').html(data.totalQty);
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-success add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.msgSuccess + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                        location.reload();
                    }, 500);
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

base.methods.editBonusProducts = function (data) {
    chooseBonusProducts(data);
};
base.addToCart = addToCart;
base.getPidValue = getPidValue;
base.getQuantitySelected = getQuantitySelected;
base.miniCartReportingUrl = miniCartReportingUrl;
base.colorAttribute = colorAttribute;
base.createCarousel = createCarousel;
base.handleVariantResponse = handleVariantResponse;
base.attributeSelect = attributeSelect;
base.parseHtml = parseHtml;
base.chooseBonusProducts = chooseBonusProducts;
base.addBonusProductsToCart = addBonusProductsToCart;
module.exports = base;
